<template>
  <div class="h-100 bg-white">
    <div class="main-container h-100">
      <v-overlay :value="showLoader" z-index="8">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-row class="ma-0 flex-column" :key="business.id">
        <div class="title text-purple d-flex align-center">
          <div v-for="(item, i) in breadCrumbs" :key="i" @click="$router.push(item.to)">
            <span>{{ item.text }}</span>
            <v-icon style="font-size: 16px;">mdi-chevron-right</v-icon>
          </div>
        </div>
        <v-row class="ma-0 my-4">
          <v-col class="d-flex align-center justify-start pa-0">
            <div style="width: 50px; height: 50px; margin-right: 15px;">
              <img
                :src="getOptimizedS3ImageURL(bannerImage, { width: 250, height: 250 })"
                :key="business.id"
                style="height: 100%; width: 100%; object-fit: cover;"
                :style="`border-radius: ${business.bannerImage && '50%'} `"
              />
            </div>
            <span class="title" :key="business.id">
              {{ business.name }}
            </span>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="ma-0 flex-column">
        <v-select
           v-if="$vuetify.breakpoint.xsOnly"
          solo
          flat
          class="type-selection notranslate tabs-dropdown"
          placeholder="Select..."
          :items="tabsList"
          item-text="name"
          item-value="value"
          v-model="parentTab"
          :menu-props="{ 'content-class': 'notranslate' }"
          append-icon="mdi-chevron-down"
          hide-details
        ></v-select>
        <v-tabs v-model="parentTab" class="portfolioTabs" v-else>
          <v-tab class="tab-title" href="#brandProfile" @change="changeStep({ step: 1 })"
            >Brand Kit</v-tab
          >
          <v-tab class="tab-title" href="#asset_library" @change="changeStep({ step: 1 })"
            >Asset Library</v-tab
          >
          <v-tab
            class="tab-title"
            href="#settings"
            v-if="!agency_customer"
            @change="changeStep({ step: 1 })"
            >Settings</v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="parentTab">
            <div class="tab-box-items">
              <v-tab-item key="brandProfile" value="brandProfile"
              :transition="false"
                :reverse-transition="false">
                <BrandProfile
                  page="org/business"
                  reference="business"
                  :businessId="$route.params.businessId"
                />
              </v-tab-item>
              <v-tab-item key="asset_library"  value="asset_library"
              :transition="false"
                :reverse-transition="false">
                <AssetLibrary
                  :step="step"
                  reference="business"
                  page="org_business"
                  :currentTab="parentTab"
                  @changeStep="(step) => changeStep(step)"
                  @selectedProjectId="selectedProject"
                />
              </v-tab-item>
              <v-tab-item key="settings" value="settings"
              :transition="false"
                :reverse-transition="false">
                <div class="settings">
                  <Settings
                    :selectedBusinessId="selectedBusinessId"
                    reference="business"
                    through="organization"
                  />
                </div>
              </v-tab-item>
            </div>
          </v-tabs-items>
      </v-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import BrandProfile from '@/views/BrandProfile';
import AssetLibrary from '@/components/AssetLibrary/AssetLibrary';
import Settings from '@/components/organizations/Settings';
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  name: 'Business',
  components: {
    BrandProfile,
    Settings,
    AssetLibrary,
  },
  data() {
    return {
      step: 1,
      loading: false,
      showLoader: false,
      selectedProjectId: '',
    };
  },
  methods: {
    ...mapActions('business', ['getBusiness']),
    getOptimizedS3ImageURL,
    selectedProject(val) {
      this.selectedProjectId = val;
    },
    changeStep(data) {
      const { step } = data;
      this.step = step;
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('business', ['business']),
    tabsList() {
      const tabs = [
        {
          name: 'Brand Kit',
          value: 'brandProfile',
        },
        {
          name: 'Asset Library',
          value: 'asset_library',
        },
      ];
      if (!this.agency_customer) {
        tabs.push({
          name: 'Settings',
          value: 'settings',
        });
      }
      return tabs;
    },
    parentTab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab || 'brandProfile';
      },
    },
    selectedBusinessId() {
      return this.$route.params.businessId;
    },
    agency_customer() {
      return ['agency_owner', 'agency_member'].includes(this.role);
    },
    bannerImage() {
      if (this.business.bannerImage) {
        return this.business.bannerImage;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/svg/businesses.svg');
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
    breadCrumbs() {
      let items = [];
      const orgId = this.$route.params.organizationId;
      const orgName = this.$route.query.agencyName;
      if (this.agency_customer) {
        items = [
          {
            text: 'All Businesses',
            disabled: false,
            to: `/organizations/${orgId}`,
          },
        ];
      } else {
        // get projectId of agency
        const projectId = this.$route.query.agencyProjectId;
        items = [
          {
            text: 'All Businesses',
            disabled: false,
            to: '/projects',
          },
          {
            text: `${orgName}`,
            disabled: false,
            to: `/project_overview/${projectId}`,
          },
        ];
      }
      return items;
    },
  },
  async mounted() {
    this.loading = true;
    const { businessId } = this.$route.params;
    this.businessId = businessId;
    await this.getBusiness(businessId);
    this.loading = false;
  },
};
</script>
<style lang="scss" scoped>
.tab-box-items {
  margin-top: 45px;
}
.text-purple {
  cursor: pointer;
  width: fit-content;
}
</style>

<template>
  <div class="h-100 bg-white">
    <div class="main-container h-100 p-8">
      <v-row class="ma-0 flex-column" v-if="canAccess">
        <div class="title text-purple d-flex align-center">
          <div v-for="(item, i) in breadCrumbs" :key="i" @click="$router.push(item.to)">
            <span>{{ item.text }}</span>
            <span><v-icon style="font-size: 16px;">mdi-chevron-right</v-icon></span>
          </div>
        </div>
        <v-row class="ma-0 my-4">
          <v-col class="d-flex align-center justify-start pa-0">
            <div style="width: 50px; height: 50px; margin-right: 15px;">
              <img
                :src="getOptimizedS3ImageURL(bannerImage, { width: 250, height: 250 })"
                :key="agency.id"
                style="height: 100%; width: 100%; object-fit: cover;"
                :style="`border-radius: ${agency.bannerImage && '50%'} `"
              />
            </div>
            <span class="title" :key="agency.id"> {{ agency.name }}</span>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="ma-0" v-if="canAccess">
        <v-tabs v-model="parentTab" class="portfolioTabs">
          <v-tab class="tab-title" key="business" @change="changeStep({ step: 1 })"
            >Businesses</v-tab
          >
          <v-tab class="tab-title" key="brandKit" @change="changeStep({ step: 1 })"
            >Brand Kit</v-tab
          >
          <v-tab class="tab-title" key="orders" @change="changeStep({ step: 1 })">Orders</v-tab>
          <v-tab class="tab-title" key="sweeps" @change="changeStep({ step: 1 })">Sweeps</v-tab>
          <v-tab class="tab-title" key="mediaLibrary" @change="changeStep({ step: 1 })"
            >Asset Library</v-tab
          >
          <v-tab class="tab-title" key="settings" @change="changeStep({ step: 1 })">Settings</v-tab>
          <v-tabs-items v-model="parentTab">
            <div class="tab-box-items">
              <v-tab-item key="business" :transition="false" :reverse-transition="false">
                <v-row class="ma-0 justify-end mb-5">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="['agency_owner', 'admin', 'executive'].includes(role)"
                        class="add-button btn-purple align-center"
                        v-bind="attrs"
                        v-on="on"
                        >Actions
                        <span class="ml-1">
                          <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M10 0L5 6L0 0H10Z" fill="white" />
                          </svg>
                        </span>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="openForm">
                        <v-list-item-title>Add Business</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="['admin', 'executive'].includes(role)"
                        @click="openImportModal"
                      >
                        <v-list-item-title>Bulk Import</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
                <v-row class="ma-0 justify-end">
                  <v-col xxl="3" xl="3" lg="4" md="5" xs="12" sm="12"
                  class="d-flex align-center justify-end pa-0" >
                    <BaseInput
                      solo
                      hide-details
                      v-model="search"
                      placeholder="Search ..."
                      prepend-inner-icon="search"
                      class="search-box"
                      clearable
                      @click:clear="search === ''"
                    />
                  </v-col>
                </v-row>
                <div class="listing-block">
                  <BusinessList
                    :businesses="agencyBusinesses"
                    :fetching="loading"
                    @onModalOpen="openEditModal"
                    :search="search"
                    @rowClicked="(row) => navigate(row)"
                    :refreshKey="refreshKey"
                  />
                </div>
              </v-tab-item>
              <v-tab-item key="brandKit" :transition="false" :reverse-transition="false">
                <BrandProfile reference="business" :businessId="$route.params.organizationId" />
              </v-tab-item>
              <v-tab-item key="orders" :transition="false" :reverse-transition="false">
                <Orders v-if="step === 1" @goToOrder="(orderId) => goToOrder(orderId)" />
                <OrderInfo
                  v-if="step === 2"
                  :orderId="selectedOrderId"
                  @backToAllOrders="backToAllOrders"
                />
              </v-tab-item>
              <v-tab-item key="sweeps" :transition="false" :reverse-transition="false">
                <Sweeps alignment="justify-left" :selectedBusinessId="selectedBusinessId" />
              </v-tab-item>
              <v-tab-item key="mediaLibrary" :transition="false" :reverse-transition="false">
                <AssetLibrary
                  reference="organization"
                  page="organization"
                  :step="step"
                  :currentTab="parentTab"
                  @changeStep="(step) => changeStep(step)"
                />
              </v-tab-item>
              <v-tab-item key="settings" :transition="false" :reverse-transition="false">
                <div class="settings">
                  <Settings
                    :selectedBusinessId="selectedBusinessId"
                    reference="organization"
                    through="organization"
                  />
                </div>
              </v-tab-item>
            </div>
          </v-tabs-items>
        </v-tabs>
      </v-row>
      <div v-else-if="role === 'agency_owner' || role === 'agency_member'">
        <v-row class="ma-0 justify-space-between align-center pb-7">
          <PageTitle />
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="['agency_owner', 'admin', 'executive'].includes(role)"
                class="add-button btn-purple align-center"
                v-bind="attrs"
                v-on="on"
                >Actions
                <span class="ml-1">
                  <svg
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 0L5 6L0 0H10Z" fill="white" />
                  </svg>
                </span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openForm">
                <v-list-item-title>Add Business</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="['admin', 'executive'].includes(role)" @click="openImportModal">
                <v-list-item-title>Bulk Import</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-row class="ma-0 mt-5 justify-end pl-1 pb-1">
          <v-col xxl="3" xl="3" lg="4" md="5" xs="12" sm="12"
          class="d-flex align-center justify-end">
            <BaseInput
              solo
              hide-details
              v-model="search"
              placeholder="Search ..."
              prepend-inner-icon="search"
              class="search-box"
              clearable
              @click:clear="search === ''"
            />
          </v-col>
        </v-row>
        <div class="listing-block">
          <BusinessList
            :businesses="agencyBusinesses"
            :fetching="loading"
            @onModalOpen="openEditModal"
            :search="search"
            @rowClicked="(row) => navigate(row)"
          />
        </div>
      </div>
    </div>
    <v-container fluid v-if="showBusinessForm">
      <Modal
        persistent
        :fullscreen="$vuetify.breakpoint.xsOnly"
        content-class="new-user-form"
        :modal="showBusinessForm"
        scrollable
      >
        <BusinessForm
          @close-modal="closeForm"
          :selectedAgency="agency"
          :businessObj="selectedBusiness"
          :canArchive="$can('delete', 'project management')"
        />
      </Modal>
    </v-container>
    <v-dialog v-model="showImportModal" persistent width="750px" max-width="1280px">
      <v-card class="pa-2 pb-0" flat>
        <div>
          <v-row class="ma-auto">
            <v-col class="d-flex align-center pa-0" cols="10">
              <span class="pa-2 title modal-header-title">Import Businesses</span>
            </v-col>
            <!-- <v-col class="d-flex justify-end pa-0">
              <v-icon color="darken-1" @click="showImportModal = false">mdi-close</v-icon>
            </v-col> -->
          </v-row>
        </div>
        <v-divider></v-divider>
        <v-card-text class="upload-modal-content">
          <div class="mt-2">
            <v-file-input
              :key="selectedFile"
              accept="text/csv"
              v-model="selectedFile"
              label="Choose a CSV File"
              @change="
                (file) => {
                  resetImportState();
                  selectedFile = file;
                }
              "
              @reset="resetImportState"
            ></v-file-input>
          </div>
          <div v-if="csvErrors.length > 0" class="ml-2">
            <div>Please fix below issues in CSV file</div>
            <div class="csv-error-wrapper ml-2" v-for="error in csvErrors" :key="error.lineNumber">
              <div class="red--text">
                <span>At line {{ error.lineNumber }}:</span>&nbsp;
                <span>{{ error.errors.join(',') }}</span>
              </div>
            </div>
          </div>
          <div v-if="serverErrors.length > 0" class="ml-2">
            <div>Below businesses were failed to import</div>
            <div
              class="csv-error-wrapper ml-2"
              v-for="error in serverErrors"
              :key="error.businessName"
            >
              <div class="red--text">
                <span>{{ error.businessName }}:</span>&nbsp; <span>{{ error.reason }}</span>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-divider class="mt-1"></v-divider>
        <v-card-actions class="card-actions px-5 py-4 justify-end">
          <v-btn text class="mr-2" color="primaryGray1" @click="showImportModal = false"
            >Cancel</v-btn
          >
          <v-btn
            class="ml-2 btn-purple"
            type="submit"
            @click="uploadBusinesses"
            :disabled="!selectedFile"
            :loading="uploadingCSVFile"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container v-if="showConfirm">
      <RemoveAffiliationPopup
        :showConfirm="showConfirm"
        @close="close"
        @remove="unAffiliate(businessId)"
        :agencyName="agency.name"
        :selectedBusinessName="selectedBusiness.name"
      />
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import RemoveAffiliationPopup from '@/components/organizations/RemoveAffiliationPopup';
import Modal from '@/components/common/Modal';
import BaseInput from '@/components/common/BaseInput';
import BusinessForm from '@/components/organizations/businesses/BusinessForm';
import BusinessList from '@/components/organizations/businesses/BusinessList';
import AssetLibrary from '@/components/AssetLibrary/AssetLibrary';
import organizationsIcon from '@/assets/svg/organisations.svg';
import Settings from '@/components/organizations/Settings';
import { getOptimizedS3ImageURL } from '@/helpers/';
import BrandProfile from '@/views/BrandProfile';
import Sweeps from '@/views/SweepManagement';
import Orders from '@/views/Orders';
import OrderInfo from '@/components/orders/SingleOrderInfo';
import PageTitle from '@/components/common/PageTitle';

export default {
  components: {
    AssetLibrary,
    BusinessForm,
    Modal,
    BusinessList,
    BaseInput,
    BrandProfile,
    Settings,
    RemoveAffiliationPopup,
    Sweeps,
    Orders,
    OrderInfo,
    PageTitle,
  },
  watch: {
    parentTab(VAL) {
      this.refreshKey = Date.now().toString();
      if (VAL === 2) {
        this.getContentPlan({ businessId: this.$route.params.organizationId });
      }
    },
  },
  computed: {
    ...mapGetters('user', ['userDetails']),
    ...mapGetters('organizations', ['agency', 'agencyBusinesses']),
    selectedBusinessId() {
      return this.$route.params.organizationId;
    },
    canAccess() {
      return ['admin', 'executive'].includes(this.role);
    },
    bannerImage() {
      if (this.agency.bannerImage) {
        return this.agency.bannerImage;
      }
      return organizationsIcon;
    },
    role() {
      return _.get(this.userDetails, 'role.name');
    },
  },
  methods: {
    ...mapActions(['setNotification']),
    ...mapActions('organizations', [
      'removeAffiliation',
      'getAgencyBusinesses',
      'getSingleAgency',
      'importBusinesses',
    ]),
    ...mapActions('profile', ['getClientOptions']),
    ...mapActions('business', ['getBusiness', 'getContentPlan']),
    goToOrder(orderId) {
      this.step = 2;
      this.selectedOrderId = orderId;
    },
    backToAllOrders(orderId) {
      this.step = 1;
      this.selectedOrderId = orderId;
    },
    changeStep(data) {
      const { step } = data;
      this.step = step;
    },
    selectedProjectId(id) {
      this.selectedLibraryProjectId = id;
    },
    close() {
      this.showConfirm = false;
    },
    openImportModal() {
      this.resetImportState();
      this.showImportModal = true;
    },
    closeImportModal() {
      this.resetImportState();
      this.showImportModal = false;
    },
    resetImportState() {
      this.selectedFile = null;
      this.csvErrors = [];
      this.serverErrors = [];
    },
    async uploadBusinesses() {
      this.uploadingCSVFile = true;
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('agencyId', this.agencyId);
      this.csvErrors = [];
      this.serverErrors = [];
      const response = await this.importBusinesses(formData);
      this.uploadingCSVFile = false;
      if (response.success) {
        // can fully imported or partial
        const { unImportedBusinesses, importState } = response;
        const serverErrors = [];
        unImportedBusinesses.forEach((business) => {
          serverErrors.push({
            businessName: business.businessName,
            reason: business.failureReason,
          });
        });
        this.serverErrors = serverErrors;
        if (importState === 'all') {
          this.setNotification(
            {
              message: 'All businesses were imported successfully',
              type: 'success',
            },
            { root: true },
          );
          await this.getAgencyBusinesses({ businessId: this.agencyId, status: 1 });
          this.closeImportModal();
        } else if (importState === 'partial') {
          this.setNotification(
            {
              message: 'Some businesses were failed to import',
              type: 'error',
            },
            { root: true },
          );
          await this.getAgencyBusinesses({ businessId: this.agencyId, status: 1 });
        } else {
          this.setNotification(
            {
              message: 'No businesses were imported',
              type: 'error',
            },
            { root: true },
          );
        }
      } else {
        // eslint-disable-next-line
        if (response.data.errors && response.data.errors.length > 0) {
          this.csvErrors = response.data.errors;
        } else {
          this.csvErrors = [];
        }
      }
    },
    navigate(row) {
      const { id, name } = row.data.business;
      let url = `/organizations/${this.agencyId}/business/${id}`;
      url += `?agencyName=${this.agency.name}&businessName=${name}`;
      this.$router.push(url);
    },
    getOptimizedS3ImageURL,
    async unAffiliate(id) {
      const result = await this.removeAffiliation({
        businessId: JSON.parse(this.agencyId),
        affiliatedBusinessId: id,
        reference: 'organization',
      });
      if (result.success) {
        this.showConfirm = false;
      }
    },
    async closeForm() {
      this.showBusinessForm = false;
      this.selectedBusiness = {};
    },
    openForm() {
      this.selectedBusiness = {};
      this.showBusinessForm = true;
    },
    openEditModal(id, type) {
      this.selectedBusiness = _.cloneDeep(_.find(this.agencyBusinesses, ['id', id]));
      const users = _.get(this.selectedBusiness, 'userBusiness');
      let primaryOwner = {};
      _.map(users, (user) => {
        if (user.user.isPrimaryOwner) {
          primaryOwner = user.user;
        }
      });
      if (type === 1) {
        this.selectedBusiness.businessTypeId = _.get(this.selectedBusiness, 'businessTypeId', null);
        this.selectedBusiness.name = _.get(this.selectedBusiness, 'name');
        this.selectedBusiness.bannerImage = _.get(this.selectedBusiness, 'bannerImage', null);
        this.selectedBusiness.status = _.get(this.selectedBusiness, 'status');
        this.selectedBusiness.subscriptionId = _.get(
          this.selectedBusiness,
          'subscriptionBlends[0].id',
        );
        this.selectedBusiness.ownerFirstName = primaryOwner.firstName;
        this.selectedBusiness.ownerLastName = primaryOwner.lastName;
        this.selectedBusiness.ownerEmail = primaryOwner.email;
        this.showBusinessForm = true;
      } else if (type === 2) {
        this.openDeleteModal(id);
      }
    },
    openDeleteModal(id) {
      this.showConfirm = true;
      this.selectedBusiness = _.cloneDeep(_.find(this.agencyBusinesses, ['id', id]));
      this.businessId = this.selectedBusiness.id;
    },
    async getAgencyBusinessByStatus(status) {
      this.loading = true;
      if (status === 'Archived') {
        await this.getAgencyBusinesses({ businessId: this.agencyId, status: 2 });
      } else {
        await this.getAgencyBusinesses({ businessId: this.agencyId, status: 1 });
      }
      this.loading = false;
    },
  },
  async mounted() {
    this.loading = true;
    const agencyId = this.$route.params.organizationId || this.userDetails.currentBusinessId;
    this.agencyId = agencyId;
    await this.getSingleAgency(agencyId);
    await this.getAgencyBusinesses({ businessId: agencyId, status: 1 });
    await this.getClientOptions();
    this.loading = false;
  },
  data() {
    return {
      refreshKey: Date.now().toString(),
      step: 1,
      breadCrumbs: [
        {
          text: 'All Organizations',
          disabled: false,
          to: '/organizations',
        },
      ],
      csvErrors: [],
      serverErrors: [],
      showImportModal: false,
      selectedFile: null,
      uploadingCSVFile: false,
      agencyId: '',
      parentTab: null,
      showBusinessForm: false,
      search: '',
      status: '',
      loading: false,
      selectedBusiness: {},
      showConfirm: false,
      businessId: '',
      selectedLibraryProjectId: '',
      selectedOrderId: '',
    };
  },
};
</script>

<style scoped lang="scss">
.tab-box-items {
  // margin-top: 45px;
  margin-top: 2rem;
}
.affiliation {
  .v-card__actions {
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #e0e0e0;
    padding: 14px 15px 6px;
    .v-btn {
      border-radius: 6px;
      font-weight: 500;
      line-height: 19px;
      font-size: 16px;
      color: $secondary3;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      ::v-deep .v-btn__content {
        text-transform: uppercase;
      }
    }
    .submit {
      ::v-deep .v-btn__content {
        color: #fff !important;
      }
      background: $colorRed !important;
      width: 112px;
      height: 40px;
      margin-left: 12px !important;
    }
    .cancel {
      background: #f5f5f5 !important;
      .v-btn__content {
        color: #828282 !important;
      }
      width: 99px;
      height: 40px;
    }
  }
  .info-text {
    padding: 28px 21px 34px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $charcoalBlack;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px !important;
      }
    }
  }
  .title-header {
    border-bottom: 1px solid #e0e0e0;
    padding: 4px 21px 21px;
    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: $charcoalBlack;
    }
  }
  .modal-heading {
    font-family: $fontFamily1;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    color: #1b1c1e !important;
  }
  .cancel-btn {
    font-family: $fontFamily1;
    font-size: 14px;
    font-weight: 500;
  }
}
.upload-modal-content {
  overflow: auto;
  max-height: 500px;
}
.organization-filter {
  border: 1px solid #b0b0b0 !important;
  max-width: 150px;
}
.text-purple {
  cursor: pointer;
  width: fit-content;
}
.padding-right {
  padding-right: 60px !important;
}
::v-deep .v-label {
  font-family: $fontFamily1;
  font-size: 14px;
  color: $neutral1;
}
::v-deep .v-text-field {
  font-family: $fontFamily1;
  font-size: 14px;
}
.search-box {
 ::v-deep {
  .v-text-field {
    height: 40px;
    font-size: 16px;
    .v-input__control > .v-input__slot {
      min-height: 40px !important;
      border: 1px solid #b0b0b0 !important;
      box-shadow: none !important;
      border-radius: 4px !important;
    }
  }
}
}
.add-button {
  ::v-deep .v-btn__content {
    font-family: $fontFamily1;
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}
.modal-heading {
  font-family: $fontFamily1;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  color: #1b1c1e !important;
}
.v-data-table {
  ::v-deep th {
    font-weight: bold;
    font-size: 16px;
    color: $neutral1 !important;
  }
  ::v-deep td {
    font-size: 14px;
    color: $neutral1;
    font-family: $fontFamily1;
  }
  ::v-deep td:nth-child(1) {
    color: #0373d1;
  }
  ::v-deep tr:nth-child(even) {
    background-color: #f8f8f8;
  }
}
.modal-card {
  position: absolute;
  right: 0px;
  width: 500px;
  font-family: $fontFamily1;
  font-size: 14px;
  top: 50px;
  z-index: 10;
  .card-list {
    background-color: #f8f8f8;
  }
}

.listing-block {
  margin-top: 20px;
  height: calc(100vh - 358px);
  overflow: auto;
}
@media (min-width: 600px) {
  .border-right {
    border-right: 1px solid #dddddd;
  }
}
@media (max-width: 600px) {
  .search-box {
    width: 500px;
    margin-right: 5px !important;
  }
  .main-container {
    padding: 40px !important;
  }
  .listing-block {
    padding: 15px;
    padding-top: 0px;
    margin-top: 0px;
    height: calc(100vh - 165px);
  }
}
</style>
